var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"public_benefit_details"},[_c('div',{staticClass:"center_block"},[_c('div',{staticClass:"video_block"},[_c('aliVideo',{attrs:{"videoid":_vm.$route.query.savepath,"isFree":_vm.isFree,"isWelfareVideo":_vm.isWelfareVideo}}),_c('div',{staticClass:"fire"}),(_vm.$store.state.userInfo.token)?_c('div',{staticClass:"praise",on:{"click":_vm.praise1}},[_c('div',{staticClass:"praise_icon",class:{ message_red: _vm.pstate }}),_c('div',[_vm._v("点赞")])]):_vm._e()],1),(_vm.$store.state.userInfo.token)?_c('div',[_c('div',{staticClass:"message_title"},[_vm._v(" 评论"),_c('span',[_vm._v("("+_vm._s(_vm.message.length)+")")])]),_c('div',{staticClass:"message_block"},[_c('div',{staticClass:"user_pic"},[_c('img',{attrs:{"src":_vm.constant.URL +
              (_vm.$store.state.userInfo.teacher
                ? _vm.$store.state.teacherInfo.photo
                : _vm.$store.state.schoolInfo.schoolLogo),"alt":""}})]),_c('div',{staticClass:"user_info_input"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.replayText1),expression:"replayText1"}],staticStyle:{"padding-bottom":"20px"},attrs:{"maxlength":"500","name":"","id":"","cols":"30","placeholder":"说说你的看法","rows":"10"},domProps:{"value":(_vm.replayText1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.replayText1=$event.target.value}}}),_c('div',{staticClass:"text_input_info"},[_c('div',{staticClass:"title_num"},[_vm._v(_vm._s(_vm.replayText1.length)+"/500")]),_c('div',{staticClass:"replay",class:_vm.replayText1.trim().length ? '' :'notAllow',on:{"click":_vm.reply1}},[_vm._v("发送")])])])]),_vm._l((_vm.message),function(item){return _c('div',{key:item.id,staticClass:"message_block"},[_c('div',{staticClass:"user_pic"},[_c('img',{attrs:{"src":_vm.constant.URL + item.userImage,"alt":""}})]),_c('div',{staticClass:"user_info"},[_c('div',{staticClass:"phone"},[_vm._v(" "+_vm._s(item.userPhone)+" "),_c('span',[_vm._v(_vm._s(item.createtime))])]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(item.content)+" ")]),_c('div',{staticClass:"btns"},[_c('div',{staticClass:"praise",on:{"click":function($event){return _vm.praise2(item)}}},[_c('div',{staticClass:"praise_icon",class:{
                  message_red:
                    item.praiselist &&
                    item.praiselist.indexOf(_vm.$store.state.userInfo.userId) > -1,
                }}),_c('div',{staticClass:"praise_num"},[_vm._v(_vm._s(item.praiseCount))])]),_c('div',{staticClass:"message"},[_c('div',{staticClass:"message_icon"}),_c('div',{staticClass:"tab",on:{"click":function($event){return _vm.openAlert(item)}}},[_vm._v("回复")])])])])])})],2):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.alertShow),expression:"alertShow"}],staticClass:"alert_message_block"},[_c('div',{staticClass:"alert_message"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.replayText2),expression:"replayText2"}],staticStyle:{"margin":"0 auto","display":"block","padding-bottom":"20px"},attrs:{"maxlength":"500","name":"","id":"","cols":"30","placeholder":"说说你的看法","rows":"10"},domProps:{"value":(_vm.replayText2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.replayText2=$event.target.value}}}),_c('div',{staticClass:"alert_message_title"},[_vm._v(_vm._s(_vm.replayText2.length)+"/500")]),_c('div',{staticClass:"close tab",on:{"click":function($event){_vm.alertShow = false}}}),_c('div',{staticClass:"alert_message_submit tab",class:_vm.replayText2.trim().length ? '' :'replyNotAllow',on:{"click":_vm.reply2}},[_vm._v("回复")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }