<template>
  <div class="public_benefit_details">
    <div class="center_block">
      <div class="video_block">
        <aliVideo :videoid="$route.query.savepath" :isFree="isFree" :isWelfareVideo="isWelfareVideo"></aliVideo>
        <div class="fire"></div>
        <div class="praise" @click="praise1" v-if="$store.state.userInfo.token">
          <div class="praise_icon" :class="{ message_red: pstate }"></div>
          <div>点赞</div>
        </div>
      </div>
      <div v-if="$store.state.userInfo.token">
        <div class="message_title" >
          评论<span>({{ message.length }})</span>
        </div>
        <div class="message_block">
          <div class="user_pic">
            <img
              :src="
                constant.URL +
                ($store.state.userInfo.teacher
                  ? $store.state.teacherInfo.photo
                  : $store.state.schoolInfo.schoolLogo)
              "
              alt=""
            />
          </div>
          <div class="user_info_input">
            <textarea
              style="padding-bottom:20px;"
              v-model="replayText1"
              maxlength="500"
              name=""
              id=""
              cols="30"
              placeholder="说说你的看法"
              rows="10"
            ></textarea>
            <div class="text_input_info">
              <div class="title_num">{{ replayText1.length }}/500</div>
              <div class="replay" :class="replayText1.trim().length ? '' :'notAllow'" @click="reply1">发送</div>
            </div>
          </div>
        </div>
        <div class="message_block" v-for="item in message" :key="item.id">
          <div class="user_pic">
            <img :src="constant.URL + item.userImage" alt="" />
          </div>
          <div class="user_info">
            <div class="phone">
              {{ item.userPhone }} <span>{{ item.createtime }}</span>
            </div>
            <div class="desc">
              {{ item.content }}
            </div>
            <div class="btns">
              <div class="praise" @click="praise2(item)">
                <div
                  class="praise_icon"
                  :class="{
                    message_red:
                      item.praiselist &&
                      item.praiselist.indexOf($store.state.userInfo.userId) > -1,
                  }"
                ></div>
                <div class="praise_num">{{ item.praiseCount }}</div>
              </div>
              <div class="message">
                <div class="message_icon"></div>
                <div class="tab" @click="openAlert(item)">回复</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert_message_block" v-show="alertShow">
      <div class="alert_message">
        <textarea
          style="margin: 0 auto; display: block;padding-bottom:20px;"
          maxlength="500"
          v-model="replayText2"
          name=""
          id=""
          cols="30"
          placeholder="说说你的看法"
          rows="10"
        ></textarea>
        <div class="alert_message_title">{{ replayText2.length }}/500</div>
        <div class="close tab" @click="alertShow = false"></div>
        <div class="alert_message_submit tab" :class="replayText2.trim().length ? '' :'replyNotAllow'" @click="reply2">回复</div>
      </div>
    </div>
  </div>
</template>

<script>
// import titleComponent from '@/components/home/title.vue'
import aliVideo from "@/components/video";
export default {
  name: "publicBenefitDetail",
  components: {
    // titleComponent,
    aliVideo,
  },
  data() {
    return {
      message: [],
      alertShow: false,
      replayText1: "",
      replayText2: "",
      tab: {},
      pstate: this.$route.query.pstate == 0 ? false : true,
      isFree:true,
      isWelfareVideo:true,  //是否为公益园视频
    };
  },
  created() {},
  mounted() {
    // 验证是否是公益园用户
    //  this.api.park
    //   .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
    //   .then((res) => {
    //     // this.phone = res.data.mobile.slice(1);
    //     if (res.code) {
    //       if (res.data !== 5) {
    //         this.$notice({
    //           message: "仅公益园用户使用",
    //         }).isShow();
    //       }
    //     }
    //   });

    this.getMessage();
  },
  methods: {
    getMessage() {
      this.api.publicBenefit
        .getMessage({
          id: this.$route.query.id,
          uid: this.$store.state.userInfo.userId,
        })
        .then((res) => {
          this.message = res.data;
        });
    },
    // 当前用户回复
    reply1() {
      if (!this.$store.state.userInfo.token) {
        this.$store.commit("setLoginState", "login");
        return;
      } else {
        if(!this.replayText1.trim()){
          this.$notice({
            message: '请输入内容',
          }).isShow();
          return;
        }
        this.api.park
          .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // this.phone = res.data.mobile.slice(1);
            if (res.code) {
              // if (res.data !== 5) {
              //   this.$notice({
              //     message: "您不是公益幼儿园，请前往校园中心进行相关教学工作",
              //   }).isShow();
              // } else {
                
              // }
              let data = {
                videoId: this.$route.query.id,
                obj: 0,
                commentId: "",
                uid: this.$store.state.userInfo.userId,
                content: this.replayText1,
              };
              this.api.publicBenefit.comment(data).then((res) => {
                if (res.flag) {
                  this.replayText1 = "";
                  this.$notice({
                    type: "success",
                    message: res.message,
                  }).isShow();
                  this.getMessage();
                } else {
                  this.$notice({
                    message: res.message,
                  }).isShow();
                }
              });
            }
          });
      }
    },
    openAlert(item) {
      this.alertShow = true;
      this.tab = { ...item };
    },
    // 当前用户评论消息
    reply2() {
      let data = {
        videoId: this.$route.query.id,
        obj: 1,
        commentId: this.tab.id,
        uid: this.$store.state.userInfo.userId,
        content: this.replayText2,
      };
      this.api.publicBenefit.comment(data).then((res) => {
        if (res.flag) {
          this.replayText2 = "";
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.alertShow = false;
          this.tab = {};
          this.getMessage();
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // 点赞视频
    praise1() {
      //
      if (!this.$store.state.userInfo.token) {
        this.$store.commit("setLoginState", "login");
        return;
      } else {
        this.api.park
          .invoiceType({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // this.phone = res.data.mobile.slice(1);
            if (res.code) {
              // if (res.data !== 5) {
              //   this.$notice({
              //     message: "您不是公益幼儿园，请前往校园中心进行相关教学工作",
              //   }).isShow();
              //   return;
              // } else {
                
              // }
              if (this.$route.query.pstate == 1) {
                this.$notice({
                  type: "success",
                  message: "本视频您已点赞",
                }).isShow();
              } else {
                let data = {
                  id: this.$route.query.id,
                  obj: 0,
                  uid: this.$store.state.userInfo.userId,
                  type: 0,
                  objId: this.$route.query.id,
                };
                this.api.publicBenefit.praise(data).then((res) => {
                  if (res.flag) {
                    this.pstate = 1;
                    this.$notice({
                      type: "success",
                      message: res.message,
                    }).isShow();
                  } else {
                    this.$notice({
                      message: res.message,
                    }).isShow();
                  }
                });
              }
            }
          });
      }
    },
    // 点赞评论
    praise2(item) {
      let data = {
        id: item.id,
        obj: 1,
        uid: this.$store.state.userInfo.userId,
        // objId: item.id,
      };
      this.api.publicBenefit.praise(data).then((res) => {
        if (res.flag) {
          this.getMessage();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.public_benefit_details {
  background: #fff;

  .center_block {
    display: block;
    width: 1289px !important;
    margin: 0 auto 150px;
  }

  .video_block {
    width: 1289px;
    height: 724px;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    background: pink;
    margin-bottom: 53px;
    margin-top: 100px;

    .video {
      width: 100%;
      height: 100%;
    }

    .fire {
      width: 43px;
      height: 59px;
      @include bg("../../assets/img/publicBenefit/fire.png");
      position: absolute;
      z-index: 111111;
      top: 17px;
      left: 35px;
    }

    .praise {
      width: 153px;
      height: 54px;
      cursor: pointer;
      background: #333333;
      border-radius: 27px;
      font-size: 24px;
      font-family: Alibaba PuHuiTi;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 21px;
      z-index: 111111;
      right: 26px;
      @include flex();

      .praise_icon {
        width: 27px;
        height: 27px;
        margin-right: 8px;
        @include bg("../../assets/img/publicBenefit/praise.png");
      }
    }
  }

  .message_title {
    width: 100%;
    text-align: left;
    font-size: 48px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #333333;
    margin-bottom: 38px;

    span {
      font-size: 24px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: #333333;
    }
  }

  .message_block {
    width: 100%;
    margin-bottom: 75px;
    @include flex(row, flex-start, flex-start);

    .user_pic {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 23px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .user_info_input {
      flex: 1;
      position: relative;

      textarea {
        width: 100%;
        height: 150px;
        border: 1px solid #666666;
        border-radius: 20px;
        padding: 10px;
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;
      }

      .text_input_info {
        @include flex();
        position: absolute;
        right: 26px;
        bottom: -60px;

        .title_num {
          font-size: 24px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #333333;
        }

        .replay {
          width: 82px;
          height: 45px;
          cursor: pointer;
          margin-left: 23px;
          background: #cc1a30;
          border-radius: 23px;
          font-size: 24px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #ffffff;
          @include flex();
        }
        .notAllow{
          background: #ffc5c2;
          pointer-events: none;
        }
      }
    }

    .user_info {
      flex: 1;
      @include flex(column, flex-start, flex-start);

      .phone {
        margin-bottom: 25px;
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;

        span {
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #777777;
          display: inline-block;
          margin-left: 22px;
        }
      }

      .desc {
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;
        margin-bottom: 24px;
      }

      .btns {
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #666666;
        @include flex(row, flex-start, center);

        .praise {
          cursor: pointer;
          @include flex();

          .praise_icon {
            width: 23px;
            height: 23px;
            margin-right: 8px;
            @include bg("../../assets/img/publicBenefit/praise_black.png");
          }
        }

        .message {
          cursor: pointer;
          @include flex();
          margin-left: 29px;

          .message_icon {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            @include bg("../../assets/img/publicBenefit/message_black.png");
          }
        }
      }
    }
  }

  .alert_message_block {
    width: 100vw;
    height: 100vh;
    @include flex();
    position: fixed;
    top: 0;
    left: 0;

    .alert_message {
      width: 1000px;
      height: 500px;
      padding-top: 50px;
      background: #f5f5f5;
      border-radius: 20px;
      position: relative;

      textarea {
        width: 85%;
        height: 350px;
        border: 1px solid #666666;
        border-radius: 20px;
        padding: 10px;
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;
        margin-bottom: 30px;
      }

      .alert_message_submit {
        width: 167px;
        height: 49px;
        @include flex();
        background: #3565b5;
        border-radius: 10px;
        font-size: 30px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #ffffff;
        margin: 40px auto 0;
      }
      .replyNotAllow{
        background: #909399;
        pointer-events: none;
      }

      .alert_message_title {
        position: absolute;

        bottom: 70px;
        right: 100px;
        font-size: 24px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #333333;
      }

      .close {
        width: 23px;
        height: 23px;
        position: absolute;
        right: 33px;
        top: 33px;
        @include bg("../../assets/img/publicBenefit/del.png");
      }
    }
  }

  .message_red {
    background-image: url("../../assets/img/publicBenefit/praise_red.png") !important;
  }
}
</style>
