<template>
  <div style="width: 100%; height: 100%">
    <div id="player-con" style="height: 100%"></div>
    <div class="alert" v-show="alertShow">
      <div class="layui-layer layui-layer-page pop-sign pop-promt">
        <a
          class="layui-layer-ico layui-layer-close layui-layer-close2"
          @click="alertShow = false"
          href="javascript:;"
        ></a>
        <div id="" class="layui-layer-content">
          <div class="promt sucess">
            <div class="tips">立即给学生评分？</div>
          </div>
        </div>
        <div class="layui-layer-btn layui-layer-btn-">
          <a class="layui-layer-btn1" @click="toEvaluate">确定</a
          ><a class="layui-layer-btn0" @click="alertShow = false">取消</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 有能力请重构 没能力请不要动这个文件 会崩

export default {
  name: "VideoBlock",
  components: {},
  props: {
    videoid: {
      //视频id
      type: String,
      default: () => null,
    },
    isFree: {
      type: Boolean,
      default: false,
    },
    isWelfareVideo: {
      type: Boolean,
      default: false,
    },
    isSportMeeting: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      player: "",
      source: [],
      timer: "",
      currentTime: "",
      duration: "", //视频总时长
      flag: true, // 视频中段调用上课方案
      evaluate: "", // 待评分id
      alertShow: false, // 评分弹窗
      oneMinuteFlag: true, //视频开始调用统计
      is10min : false
    };
  },
  watch: {
    currentTime(val) {
      //判断是否是公益园视频
      if (this.isWelfareVideo) return;
      if(this.isSportMeeting) return;
      let data = {
        userId: this.$store.state.userInfo.userId,
        videoId: parseInt(this.$route.query.id),
        classTime: parseInt(this.currentTime),
        evaluatestatus: "0",
        classstatus: "1",
      };
      if (this.$route.query.gradeId)
        data.gradeId = parseInt(this.$route.query.gradeId);
      if (this.$route.query.teacherId)
        data.teacherId = this.$route.query.teacherId;
      if (this.$route.query.studentIds)
        (data.studentIds = this.$route.query.studentIds),
          // 上课视频调用
          (this.duration = this.player.getDuration());
      if (parseInt(val) > 60 && this.oneMinuteFlag) {
        if (this.$route.query.gradeId) {
          this.api.unit.startAddClassRecord(data).then((res) => {
            // console.log(res);
            this.evaluate = res.data;
            this.oneMinuteFlag = false;
          });
        } else {
          this.api.unit.startVideoRecord(data).then((res) => {
            // console.log(res);
            this.evaluate = res.data;
            this.oneMinuteFlag = false;
          });
        }
      }
      // if (
      //   parseInt(val) > parseInt(this.duration) / 2 &&
      //   this.flag
      // ) {
      //   this.api.unit
      //     .addNewClassRecord(data)
      //     .then((res) => {
      //       // console.log(res);
      //       this.evaluate = res.data;
      //       this.flag = false;
      //     });
      // }
    },
  },
  computed: {},
  created() {},
  async mounted() {
    //如果为体验课 去掉是否购课校验 判断视频是否获取成功
    if (this.isFree) {
      await this.getFreeSource();
    } else {
      if (this.$route.query.welfare) {
        await this.getSource();
      } else {
        await this.getSource1();
      }
    }
  },
  methods: {
    getSource() {
      let that = this;
      this.api.unit
        .getPlayInfo({ videoId: this.videoid, definition: "" })
        .then((res) => {
          // console.log(res);
          this.source = {};
          res.data.playInfoList.forEach((item) => {
            this.source[item.definition] = item.playURL;
          });
          this.$nextTick(() => {
            this.player = new window.Aliplayer(
              {
                format: "m3u8",
                encryptType: "1",
                id: "player-con",
                source: JSON.stringify(this.source),
                defaultDefinition: "SD",
                autoplay: false,
                // cover: '{$course.cover}',
                isLive: false,
                rePlay: false,
                playsinline: true,
                preload: true,
                controlBarVisibility: "hover",
                useH5Prism: true,
              },
              function (player) {
                console.log(player);
              }
            );
            this.player.on("ready", function () {});
            this.player.on("play", function () {
              //视频开始触发
              that.duration = that.player.getDuration();
              that.getTime();
            });
            // this.player.on("ended", function () {
            //   //视频结束触发事件
            //   console.log(1234);
            //   that.duration = that.player.getDuration();
            //   that.api.unit
            //     .addNewClassRecord({
            //       gradeId: parseInt(that.$route.query.gradeId),
            //       studentIds: that.$route.query.studentIds,
            //       teacherId: that.$route.query.teacherId,
            //       userId: that.$store.state.userInfo.userId,
            //       videoId: parseInt(that.$route.query.id),
            //       classTime: parseInt(that.currentTime),
            //       evaluatestatus: "0",
            //       classstatus: "1",
            //     })
            //     .then((res) => {
            //       // console.log(res);
            //       that.evaluate = res.data;
            //       that.flag = false;
            //       if (!that.$route.query.gradeId) {
            //         that.alertShow = false;
            //         // this.$router.push("/home");
            //       } else {
            //         if (that.$route.query.isSports) {
            //           that.alertShow = false;
            //         } else {
            //           that.alertShow = true;
            //         }
            //       }
            //     });
            //   that.clear();
            // });
            this.player.on("pause", function () {
              //暂停
              // console.log(123);
              that.duration = that.player.getDuration();
              that.clear();
            });
            this.player.on("error", function () {
              ///报错
              // console.log(123);
              that.clear();
            });
          });
        });
    },
    getSource1() {
      let that = this;
      this.api.unit
        .getPlayInfoByToken({ videoId: this.videoid, definition: "" })
        .then((res) => {
          // console.log(res);
          this.source = {};
          res.data.playInfoList.forEach((item) => {
            this.source[item.definition] = item.playURL;
          });

          this.$nextTick(() => {
            this.player = new window.Aliplayer(
              {
                format: "m3u8",
                encryptType: "1",
                id: "player-con",
                source: JSON.stringify(this.source),
                defaultDefinition: "SD",
                autoplay: false,
                // cover: '{$course.cover}',
                isLive: false,
                rePlay: false,
                playsinline: true,
                preload: true,
                controlBarVisibility: "hover",
                useH5Prism: true,
              },
              function (player) {
                console.log(player);
              }
            );
            this.player.on("ready", function () {});
            this.player.on("play", function () {
              //视频开始触发
              that.duration = that.player.getDuration();
               that.getTime();
              if(that.$route.query.status ==1 && that.$route.query.type == 1){
                console.log('视频开始1111j是不是这儿')
                let timer = setInterval(() => {
                  if (that.player.getCurrentTime() >= 600) {
                    console.log('已播放10分钟')
                    that.is10min = true
                    that.player.pause()
                    that.$notice({
                      message: "备案课程查看已结束，上课请点击一键上课",
                    }).isShow();
                    clearInterval(timer)
                  } else {
                    console.log('已播放' + that.player.getCurrentTime() + '秒')
                  }
                }, 1000)
              }

              // setTimeout(function () {
              //   console.log('开始倒计时1213123')
              //   that.player.pause()
              // }, 100000)

            });
            this.player.on("pause", function () {
              //暂停
              // console.log(123);
              that.duration = that.player.getDuration();
              that.clear();
            });
            this.player.on("error", function () {
              ///报错
              // console.log(123);
              that.clear();
            });
          });
        });
    },
    getFreeSource() {
      let that = this;
      this.api.park
        .getFreeCoursePlay({ videoId: this.videoid, definition: "" })
        .then((res) => {
          // console.log(res);
          this.source = {};
          res.data.playInfoList.forEach((item) => {
            this.source[item.definition] = item.playURL;
          });

          this.$nextTick(() => {
            this.player = new window.Aliplayer(
              {
                format: "m3u8",
                encryptType: "1",
                id: "player-con",
                source: JSON.stringify(this.source),
                defaultDefinition: "SD",
                autoplay: false,
                // cover: '{$course.cover}',
                isLive: false,
                rePlay: false,
                playsinline: true,
                preload: true,
                controlBarVisibility: "hover",
                useH5Prism: true,
              },
              function (player) {
                console.log(player);
              }
            );
            this.player.on("ready", function () {});
            this.player.on("play", function () {
              //视频开始触发
              that.duration = that.player.getDuration();
              that.getTime();
            });
            // this.player.on("ended", function () {
            //   //视频结束触发事件
            //   console.log(1234);
            //   that.duration = that.player.getDuration();
            //   that.api.unit
            //     .addNewClassRecord({
            //       gradeId: parseInt(that.$route.query.gradeId),
            //       studentIds: that.$route.query.studentIds,
            //       teacherId: that.$route.query.teacherId,
            //       userId: that.$store.state.userInfo.userId,
            //       videoId: parseInt(that.$route.query.id),
            //       classTime: parseInt(that.currentTime),
            //       evaluatestatus: "0",
            //       classstatus: "1",
            //     })
            //     .then((res) => {
            //       // console.log(res);
            //       that.evaluate = res.data;
            //       that.flag = false;
            //       if (!that.$route.query.gradeId) {
            //         that.alertShow = false;
            //         // this.$router.push("/home");
            //       } else {
            //         if (that.$route.query.isSports) {
            //           that.alertShow = false;
            //         } else {
            //           that.alertShow = true;
            //         }
            //       }
            //     });
            //   that.clear();
            // });
            this.player.on("pause", function () {
              //暂停
              // console.log(123);
              that.duration = that.player.getDuration();
              that.clear();
            });
            this.player.on("error", function () {
              ///报错
              // console.log(123);
              that.clear();
            });
          });
        });
    },

    getTime() {
      //to do
      this.currentTime = this.player.getCurrentTime();
      this.timer = setTimeout(this.getTime, 500);
      this.videoEnd(this.currentTime);
    },
    //清除定时器
    clear() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },

    toEvaluate() {
      this.$router.push({
        path: "/evaluate",
        query: {
          id: this.evaluate,
          videoId: this.$route.query.id,
          gradeId: this.$route.query.gradeId,
        },
      });
    },
    // 视频结束打开弹窗
    videoEnd(val) {
      if(this.isSportMeeting) return;
      if (!this.$route.query.gradeId) {
        console.log(123);
        //免费视频
        if (parseInt(val) === parseInt(this.duration)) {
          this.duration = this.player.getDuration();
          this.api.unit.addVideo(parseInt(this.$route.query.id)).then((res) => {
            this.evaluate = res.data;
            this.flag = false;
          });
          this.clear();
          this.player.pause();
        }
      } else {
        console.log("视频结束-付费课");
        console.log(parseInt(val), parseInt(this.duration), "时间");
        if (parseInt(val) >= parseInt(this.duration) - 3) {
          console.log("video_end");
          this.duration = this.player.getDuration();
          this.api.unit
            .addNewClassRecord({
              gradeId: parseInt(this.$route.query.gradeId),
              studentIds: this.$route.query.studentIds,
              teacherId: this.$route.query.teacherId,
              userId: this.$store.state.userInfo.userId,
              videoId: parseInt(this.$route.query.id),
              classTime: parseInt(this.currentTime),
              evaluatestatus: "0",
              classstatus: "1",
            })
            .then((res) => {
              if (
                this.$route.query.welfare &&
                this.$store.state.teacherInfo.userId
              ) {
                this.evaluate = res.data;
                this.flag = false;
                this.alertShow = true;
              } else if (
                this.$route.query.welfare &&
                !this.$store.state.userInfo.teacherId
              ) {
                this.evaluate = res.data;
                this.flag = false;
                // this.alertShow = true;
                // this.$router.push("/home");
              } else {
                this.evaluate = res.data;
                this.flag = false;
                if (!this.$route.query.gradeId) {
                  this.alertShow = false;
                  // this.$router.push("/home");
                } else {
                  if (this.$route.query.isSports) {
                    this.alertShow = false;
                  } else if (this.$store.state.teacherInfo.userId) {
                    this.alertShow = true;
                  }
                }
              }
            });
          this.clear();
          this.player.pause();
        }
      }
    },
  },

};
</script>
<style>
.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
  margin-left: -1em !important;
  width: 2em !important;
  outline: none;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .vjs-custom-skin > .video-js .vjs-control */

/*control-bar布局时flex，通过order调整剩余时间的位置到进度条右边*/
.vjs-custom-skin > .video-js .vjs-control-bar .vjs-remaining-time {
  order: 3 !important;
}

/*进度条背景轨道*/
.video-js .vjs-slider {
  border-radius: 1em;
}

/*进度条进度*/
.vjs-custom-skin > .video-js .vjs-play-progress,
.vjs-custom-skin > .video-js .vjs-volume-level {
  border-radius: 1em;
}

/*鼠标进入播放器后，播放按钮颜色会变*/
.video-js:hover .vjs-big-play-button,
.vjs-custom-skin > .video-js .vjs-big-play-button:active,
.vjs-custom-skin > .video-js .vjs-big-play-button:focus {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

/*control bar*/
.video-js .vjs-control-bar {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

/*点击按钮时不显示蓝色边框*/
.video-js .vjs-control-bar button {
  outline: none;
}
</style>
<style lang="scss" scoped>
.alert {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
  @include flex();

  .pop-common {
    border-radius: 0.15rem !important;
    border: solid 10px #cc1a30;
    width: 18%;
    background: #fff;
    position: relative;
  }

  .sharePop .url {
    color: #cc1a30;
    font-size: 0.24rem;
    white-space: pre-wrap;
    word-break: break-all;
    margin-bottom: 0.2rem;
  }

  .pop-common .layui-layer-content {
    padding: 0.4rem 0.45rem;
    font-size: 0.2rem;
    line-height: 0.35rem;
  }

  .sharePop {
    text-align: center;
  }

  .sharePop .img {
    margin-bottom: 0.2rem;
  }

  .sharePop .act {
    height: 0.1rem;
    line-height: 0.1rem;
    background: #cc1a30;
    color: #fff;
    display: inline-block;
    font-size: 0.24rem;
    font-weight: bold;
    padding: 0.2rem 0.4rem;
    margin: 0px 0.2rem;
    cursor: pointer;
  }

  .layui-layer-close2 {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 36px;
    height: 32px;
    margin-left: 0;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    // background-position: -146px -32px; /*no*/
    @include bg("../assets/img/close.png");
  }
  /* 弹窗 */
  .pop-promt.layui-layer {
    border: solid 0.1rem #cb1b31;
    width: 6.8rem !important;
    height: 5.6rem !important;
    @include trans-center();
  }

  .pop-promt .promt {
    padding-top: 2.5rem;
    text-align: center;
  }

  .pop-promt .layui-layer-btn .layui-layer-btn0 {
    background: #f5d1d6 !important;
  }

  .pop-promt .layui-layer-btn .layui-layer-btn1 {
    background: #cb1b31 !important;
  }

  .pop-promt .layui-layer-content {
    font-size: 0.24rem;
    line-height: 0.4rem;
    color: #cb1b31;
    overflow: hidden;
  }

  .pop-promt .sucess {
    background: url(../assets/img/sucess.png) center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .warn {
    background: url(../assets/img/warn.png) center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .fail {
    background: url("../assets/img/fail.png") center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .layui-layer-btn {
    text-align: center;
    display: flex;
    padding: 0px 0.7rem 0.4rem 0.7rem;
    position: absolute;
    width: 100%;
    bottom: 0.4rem;
    box-sizing: border-box;
  }

  .pop-promt .layui-layer-btn a {
    height: 0.7rem;
    font-size: 0.28rem;
    color: #fff;
    border: none;
    line-height: 0.7rem;
    border-radius: 0.5rem;
    flex: 1;
    margin: 0 15px;
    text-align: center;
    font-weight: 400;
    padding: 0;
  }

  .promt .tips {
    font-size: 0.32rem;
    line-height: 0.4rem;
  }

  .promt .desc {
    font-size: 0.24rem;
    line-height: 0.4rem;
  }

  .layui-layer {
    font-size: 0.18rem;
  }

  .iframe {
  }

  .iframe html {
    font-size: 100px;
  }

  .layui-layer-resize {
    display: none;
  }
}
</style>
